import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "./HelpCenter.style";
import { HELP_ICON } from "@images";

interface HelpCenterProps {
  listHelpData: {
    id: number;
    help: string | Element;
    res: string;
  }[];
  listHelpData2: {
    id: number;
    help: string | Element;
    res: string;
  }[];
  listHelpData3: {
    id: number;
    help: string | Element;
    res: string;
  }[];
}

const HelpCenterList = ({
  listHelpData,
  listHelpData2,
  listHelpData3,
}: HelpCenterProps) => {
  const [isSelect, setSelect] = useState(0);
  const [isSelect2, setSelect2] = useState(0);
  const [isSelect3, setSelect3] = useState(0);

  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className="boxBackground" />
      <h1 className={classes.title}>
        <img src={HELP_ICON} />
        Centro de Ayuda
      </h1>
      <section className={classes.section}>
        <h2 className="title1">ACERCA DE ROKIN</h2>
        <div>
          {listHelpData.map((helpData) => {
            return (
              <div
                className={classes.containerFaq}
                key={helpData.id}
                onClick={() =>
                  setSelect(isSelect === helpData.id ? 0 : helpData.id)
                }
              >
                <div className={classes.txts}>
                  <div className="title">{helpData.help}</div>
                  <AddIcon
                    className={`${classes.arrow} ${
                      isSelect === helpData.id && classes.arrowRotate
                    }`}
                  />
                </div>

                {
                  <div
                    className={`${classes.rta} ${
                      isSelect === helpData.id
                        ? classes.rtaBlock
                        : classes.rtaNone
                    }`}
                  >
                    {helpData.res}
                  </div>
                }
              </div>
            );
          })}
        </div>
        <h2 className="title1 TOP">SEGURIDAD</h2>
        <div>
          {listHelpData2.map((helpData) => {
            return (
              <div
                className={classes.containerFaq}
                key={helpData.id}
                onClick={() =>
                  setSelect2(isSelect2 === helpData.id ? 0 : helpData.id)
                }
              >
                <div className={classes.txts}>
                  <div className="title">{helpData.help}</div>
                  <AddIcon
                    className={`${classes.arrow} ${
                      isSelect2 === helpData.id && classes.arrowRotate
                    }`}
                  />
                </div>

                {
                  <div
                    className={`${classes.rta} ${
                      isSelect2 === helpData.id
                        ? classes.rtaBlock
                        : classes.rtaNone
                    }`}
                  >
                    {helpData.res}
                  </div>
                }
              </div>
            );
          })}
        </div>
        <h2 className="title1 TOP">INVERSIONES</h2>
        <div>
          {listHelpData3.map((helpData) => {
            return (
              <div
                className={classes.containerFaq}
                key={helpData.id}
                onClick={() =>
                  setSelect3(isSelect3 === helpData.id ? 0 : helpData.id)
                }
              >
                <div className={classes.txts}>
                  <div className="title">{helpData.help}</div>
                  <AddIcon
                    className={`${classes.arrow} ${
                      isSelect3 === helpData.id && classes.arrowRotate
                    }`}
                  />
                </div>

                {
                  <div
                    className={`${classes.rta} ${
                      isSelect3 === helpData.id
                        ? classes.rtaBlock
                        : classes.rtaNone
                    }`}
                  >
                    {helpData.res}
                  </div>
                }
              </div>
            );
          })}
        </div>
      </section>
    </section>
  );
};
export default HelpCenterList;
