import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    "& .boxBackground": {
      background:
        "linear-gradient(180deg, rgba(10,86,105,1) 31%, rgba(10,89,109,1) 36%, rgba(0,169,194,1) 100%);",
      width: "100%",
      height: "40rem",
      position: "absolute",
      top: 0,
      zIndex: 1,
      borderRadius: 40,
    },
    "& .itemList": {
      fontFamily: "Montserrat",
      fontWeight: 300,
      fontSize: "0.85rem",
      "& ul": {
        listStyleType: "disc",
        paddingLeft: "2rem",
      },
      "& li": { padding: "0.5rem 0" },
      "& .remarcar": {
        fontWeight: 600,
      },
    },
  },
  section: {
    /*  display: "grid",
    justifyContent: "center",
    "& div": {
      width: 1000,
    }, */
    boxShadow: "4px 4px 15px 5px rgba(0, 0, 0, 0.25)",
    background: "white",
    margin: "2rem auto",
    width: "59rem",
    position: "relative",
    zIndex: 9,
    padding: "3rem 3rem 5rem",
    borderRadius: 40,
    "& .title1": {
      width: "800px",
      color: theme.palette.text.secondary,
      margin: "0 auto",
      padding: "0",
      fontSize: "0.75rem",
      [theme.breakpoints.down(1440)]: {
        width: "100%",
      },
    },
    "& .TOP": {
      marginTop: "2rem",
    },
    [theme.breakpoints.down(1440)]: {
      width: "100%",
      maxWidth: "50rem",
    },
  },
  arrowRotate: {
    transition: "0.5s",
    transform: "rotate(90deg)",
    zIndex: 9,
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: "2.25rem",
    fontWeight: 400,
    color: "white",
    display: "flex",
    justifyContent: "center",
    gap: "2rem",
    padding: "3rem 0 1rem",
    position: "relative",
    zIndex: 9,
    [theme.breakpoints.down(1440)]: {
      fontSize: "1rem",
      alignItems: "center",
      "& img": {
        width: "2rem",
      },
    },
  },
  containerFaq: {
    width: 815,
    margin: "0 auto",
    [theme.breakpoints.down(1440)]: {
      width: "100%",
    },
  },
  txts: {
    display: "flex",
    justifyContent: "space-between",
    color: "#7d7c7c",
    padding: "1rem 0",
    borderBottom: `1px solid rgba(0,0,0,0.1)`,
    "& .title": {
      fontSize: "1rem",
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down(1440)]: {
      fontSize: "0.87rem",
    },
  },
  rta: {
    color: "#7d7c7c",
    fontSize: "1rem",
    display: "block",
    padding: "0.5rem",
  },
  arrow: {
    color: "#008296",
    transition: "0.5s",
  },
  rtaBlock: {
    transition: "0.5s",
    display: "block",
  },
  rtaNone: {
    transition: "0.5s",
    display: "none",
  },
}));
export default useStyles;
