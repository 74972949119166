import React from "react";
import { PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { LayoutBild, SEO } from "@components";
import HelpCenterList from "@components/HelpCenter/HelpCenter";
import { useAuth } from "@hooks";

const HelpCenter: React.FC<PageProps> = () => {

  return (
    <>
      <SEO
        title="Rokin - Centro de Ayuda"
        description="¿Tienes dudas, comentarios o sugerencias? Encuentra preguntas frecuentes y tips para aprovechar al máximo tu cuenta Rokin."
      />
      <LayoutBild helpCenter key="Bottom">
        <HelpCenterList
          listHelpData={listHelpData}
          listHelpData2={listHelpData2}
          listHelpData3={listHelpData3}
        />
      </LayoutBild>
    </>
  );
};

export default HelpCenter;

const useStyles = makeStyles((theme) => ({}));

const listHelpData = [
  {
    id: 1,
    help: "¿Qué es Rokin?",
    res: (
      <div className={"itemList"}>
        Rokin es una plataforma que te ayuda a gestionar y analizar tus finanzas
        personales, conocer el mundo financiero y descubrir herramientas para
        cuidar tu salud financiera y la de tu familia, todo en un solo lugar.
      </div>
    ),
  },
  {
    id: 2,
    help: "¿Qué es la Rokipedia?",
    res: (
      <div className={"itemList"}>
        Es la sección de educación financiera de la plataforma. Al completar las
        misiones de cada unidad podrás aprender sobre distintos temas tales como
        en qué debes fijarte al pedir un crédito o cómo armar un presupuesto.
        También podrás acceder a herramientas que te servirán para mejorar tu
        situación financiera y manejar tu plata de la mejor manera.
      </div>
    ),
  },
  {
    id: 3,
    help: "¿Cómo me contacto con soporte?",
    res: (
      <div className={"itemList"}>
        Puedes escribir directamente al chat que se encuentra en la esquina
        inferior derecha o puedes contactarnos a través de hola@rokinapp.com y
        te responderemos a la brevedad!
      </div>
    ),
  },
];
const listHelpData2 = [
  {
    id: 1,
    help: "¿Es Rokin seguro?",
    res: (
      <div className={"itemList"}>
        Para nosotros, la seguridad es lo primero, por eso contamos con las
        siguientes medidas para asegurarnos de que tus datos estén protegidos.
        <ul>
          <li>
            Con la data que nos proporcionas nunca podremos realizar
            transacciones por ti, sino solamente acceder en modo de lectura para
            mostrarte tu información. Las claves de acceso a tu banco u otras
            entidades que decidas agregar a Rokin, servirán para poder mostrarte
            los movimientos de cuentas y tarjetas, y darte recomendaciones para
            mejorar tus finanzas personales. Nunca te pediremos claves u otra
            información para realizar operaciones.
          </li>
          <li>
            Al momento que ingresas tus usuarios y contraseñas en nuestra
            plataforma, toda la información de tus cuentas está encriptada. Tus
            datos personales están protegidos y son estrictamente
            confidenciales.
          </li>
          <li>
            Estamos continuamente trabajando en fortalecer nuestra seguridad.
          </li>
        </ul>
      </div>
    ),
  },
];
const listHelpData3 = [
  {
    id: 1,
    help: "¿Quién es Vector Capital?",
    res: (
      <div className={"itemList"}>
        Es una Corredora de Bolsa regulada por la CMF que se encarga de recibir
        las transferencias, realizar las transacciones en la Bolsa de Santiago,
        y mantener la custodia de tus inversiones.
      </div>
    ),
  },
  {
    id: 6,
    help: "¿Cuál es el monto mínimo de inversión?",
    res: <div className={"itemList"}>Puedes invertir desde $5.000 pesos.</div>,
  },
  {
    id: 2,
    help: "¿Tengo que pagar algo por poder invertir en la plataforma?",
    res: (
      <div className={"itemList"}>
        Si inviertes con nosotros, cobramos 667 por cada 1.000.000 de pesos
        invertido (un 0,8% anual IVA incluido). Somos la plataforma con menor
        costo del mercado. No cumpliríamos nuestro objetivo si cobráramos y
        solamente ayudáramos a la gente que puede pagarlo.
      </div>
    ),
  },
  {
    id: 3,
    help: "¿Puedo rescatar mis inversiones en cualquier momento?",
    res: (
      <div className={"itemList"}>
        Los fondos de los portafolios de Rokin son fondos de inversión que se
        transan diariamente, por lo que puedes rescatar tu inversión cuando
        quieras. Recibirás tu dinero en un plazo máximo de 4 días hábiles.
      </div>
    ),
  },
  {
    id: 4,
    help: "¿Porque es recomendable invertir tus ahorros?",
    res: (
      <div className={"itemList"}>
        El principal objetivo de invertir es no perder poder adquisitivo con el
        paso del tiempo. El precio de los bienes y servicios generalmente
        aumenta año a año, conocido como inflación. Por lo tanto invertir nos
        permite que nuestros ahorros no pierdan valor en el tiempo. Si mantienes
        tu plata depositada en una cuenta de banco, si bien a final de año tu
        monto ahorrado se mantiene igual, dicho monto te permite comprar menos
        cosas, dado que estas tienen un precio mayor en la medida en que la
        inflación haya hecho subir el precio de ellas.
      </div>
    ),
  },
  {
    id: 5,
    help: "¿Está garantizada la rentabilidad por invertir?",
    res: (
      <div className={"itemList"}>
        En general, los instrumentos de inversión, no garantizan rentabilidad.
        La excepción a esto son los depósitos a plazo, donde el banco se
        compromete a devolverte tu aporte más un monto fijo adicional. El resto
        de las alternativas de inversión son instrumentos de riesgo, donde
        rentabilidades pasadas no garantizan resultados futuros.
      </div>
    ),
  },
];
